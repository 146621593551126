import React from 'react';
import './ExperienceCard.css';

const ExperienceCard = () => {
  return (
    <div className="experience-container">
         <div className="section-title">
        <span role="img" aria-label="Experience Emoji">
          🌟
        </span>{" "}
        Chronicles of Contribution
      </div>
      <div className="experience-cards-row">
      <div className="experience-card">
        {/* Company Logo */}
        {/* <img src="alpha5-logo.png" alt="Alpha5" className="company-logo" /> */}
        {/* Company and Role Information */}
        <h2>Alpha5 - Frontend Developer</h2>
        <p className="date">March 2020 - September 2021</p>
        {/* Description */}
        <p className="description">
          As a Frontend Developer at Alpha5, I contributed to the development of the client and admin dashboards
          for the cryptocurrency exchange platform. I worked closely with the design and backend teams to implement
          user-friendly and responsive interfaces. It was an exciting experience in the fast-paced world of crypto.
        </p>
      </div>
      <div className="experience-card">
        {/* Company Logo */}
        {/* <img src="pickrr-logo.png" alt="Pickrr" className="company-logo" /> */}
        {/* Company and Role Information */}
        <h2>Pickrr - Frontend Developer</h2>
        <p className="date">September 2021 - October 2022</p>
        {/* Description */}
        <p className="description">
          At Pickrr, a logistics aggregator startup, I took on various frontend tasks, including working on the product
          user interfaces and leading the development of in-house web applications like sales dashboard and tracking page.
          It was a great opportunity to enhance my skills and lead exciting projects.
        </p>
      </div>
      <div className="experience-card">
        {/* Company Logo */}
        {/* <img src="applied-materials-logo.png" alt="Applied Materials" className="company-logo" /> */}
        {/* Company and Role Information */}
        <h2>Applied Materials - Full Stack Engineer</h2>
        <p className="date">Current Employment</p>
        {/* Description */}
        <p className="description">
          Currently, I am working as a Full Stack Engineer at Applied Materials. I actively contribute to various
          in-house projects and collaborate with teams to ensure high-quality deliverables. It's been a rewarding experience
          where I can apply my capabilities to create valuable solutions.
        </p>
      </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
