import React from 'react';
import './Avatar.css';
// import avatarImage from '../path/to/avatar.jpg'; // Update the path to your actual avatar image

const Avatar = () => {
  return (
    <div className="avatar-container">
      {/* Avatar Image (on the right) */}
      <div className="avatar-image-container">
        <img src='https://res.cloudinary.com/prashantimages/image/upload/v1691351193/Screenshot_2023-08-07_at_1.15.16_AM_i7ytsf.png' alt="Your Name" className="avatar-image" />
      </div>

      {/* Your Information (on the left) */}
      <div className="avatar-info">
        <h1>Prashant Tongra 😎</h1>
        <p>Full Stack Developer 🚀</p>
        <p>
          Hey there! I'm a passionate Full Stack Developer with 3 years of experience. I enjoy building web applications
          that provide great user experiences and solve real-world problems. My expertise lies in HTML, CSS,
          JavaScript, React, and Node.js. Let's create something amazing together! 💻🎉
        </p>
      </div>
    </div>
  );
};

export default Avatar;
