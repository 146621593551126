import React from 'react';
import './App.css';
import Avatar from './components/Avatar';
import SkillsCard from './components/SkillsCard';
import ExperienceCard from './components/ExperienceCard';
import ContactSection from './components/ContactSection';

const App = () => {
  return (
    <div className="app-container">
      {/* First Section */}
      <div className="section-half-height">
        <Avatar />
      </div>

      {/* Second Section */}
      <div className="section-half-height">
        <SkillsCard />
      </div>

      {/* Third Section */}
      <div className="section-half-height">
        <ExperienceCard />
      </div>

      {/* Last Section */}
      <div className="section-half-height">
        <ContactSection />
      </div>
    </div>
  );
};

export default App;
