import React from 'react';
import './SkillsCard.css';

const SkillsCard = () => {
  return (
    <div className="skills-container">
         <div className="section-title">
        <span role="img" aria-label="Skills Emoji">
          🛠️
        </span>{" "}
        Crafted Competencies
      </div>
      <div className="skills-card">
        {/* Skill Logo */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="React" className="skill-logo" />
        {/* Skill Name */}
        <h2>React</h2>
      </div>
      <div className="skills-card">
        {/* Skill Logo */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg" alt="JavaScript" className="skill-logo" />
        {/* Skill Name */}
        <h2>JavaScript</h2>
      </div>
      <div className="skills-card">
        {/* Skill Logo */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg" alt="HTML" className="skill-logo" />
        {/* Skill Name */}
        <h2>HTML</h2>
      </div>
      <div className="skills-card">
        {/* Skill Logo */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg" alt="CSS" className="skill-logo" />
        {/* Skill Name */}
        <h2>CSS</h2>
      </div>
      <div className="skills-card">
        {/* Skill Logo */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg" alt="CSS" className="skill-logo" />
        {/* Skill Name */}
        <h2>Node</h2>
      </div>
      <div className="skills-card">
        {/* Skill Logo */}
        <img src="https://upload.wikimedia.org/wikipedia/commons/9/98/Solidity_logo.svg" alt="CSS" className="skill-logo" />
        {/* Skill Name */}
        <h2>Solidity</h2>
      </div>
    </div>
  );
};

export default SkillsCard;