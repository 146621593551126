import React from 'react';
import './ContactSection.css';

const ContactSection = () => {
  return (
    <div className="contact-container">
      {/* LinkedIn Icon */}
      <a href="https://www.linkedin.com/in/prashant-tongra-52a564151/" target="_blank" rel="noopener noreferrer">
        <img src="https://upload.wikimedia.org/wikipedia/commons/7/7e/LinkedIn_PNG16.png" alt="LinkedIn" className="contact-icon" />
      </a>
      {/* Twitter Icon */}
      <a href="https://twitter.com/PrashantTongra" target="_blank" rel="noopener noreferrer">
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Logo_of_Twitter.svg" alt="Twitter" className="contact-icon" />
      </a>
    </div>
  );
};

export default ContactSection;
